import { useEffect, useState } from 'react'
import Sugar from 'sugar-date'

import { holidayConfigs } from '../config/config'

import useCurrentDate from './useCurrentDate.hook'

const useGetHolidays = () => {
  const currentDate = useCurrentDate()
  const [holidays, setHolidays] = useState([])

  useEffect(() => {
    const holidays = []

    holidayConfigs.forEach((holidayConfig) => {
      let sugarDate = Sugar.Date(
        Sugar.Date.create(holidayConfig.date(currentDate.getFullYear()))
      )

      if (sugarDate.secondsUntil() >= 0) {
        holidayConfig.frequency === 'week'
          ? sugarDate.advance('1 week')
          : (sugarDate = Sugar.Date(
              Sugar.Date.create(
                holidayConfig.date(currentDate.getFullYear() + 1)
              )
            ))
      }

      holidayConfig.advance && sugarDate.advance(holidayConfig.advance)
      holidayConfig.rewind && sugarDate.rewind(holidayConfig.rewind)

      const remainders = {
        daysUntil: sugarDate.daysUntil().raw * -1,
        hoursUntil: (sugarDate.hoursUntil().raw % 24) * -1,
        minutesUntil: (sugarDate.minutesUntil().raw % 60) * -1,
        secondsUntil: ((sugarDate.secondsUntil().raw % 60) % 60) * -1,
      }

      holidayConfig.enabled &&
        holidays.push({ ...holidayConfig, sugarDate, ...remainders })
    })
    setHolidays(holidays)
  }, [currentDate])

  return holidays
}

export default useGetHolidays
