import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import validateHoliday from '../../utils/validateHoliday/validateHoliday'

import Holiday from '../views/Holiday/Holiday.component'

export const HolidayValidation = ({ holidays }) => {
  const [holidayValidationPending, setHolidayValidationPending] = useState(true)
  const [isValidHoliday, setIsValidHoliday] = useState(false)
  const requestedHoliday = useParams().requestedHoliday.toLowerCase()

  useEffect(() => {
    setIsValidHoliday(validateHoliday(requestedHoliday))
    setHolidayValidationPending(false)
  }, [requestedHoliday])

  return holidayValidationPending ? (
    <></>
  ) : isValidHoliday ? (
    <Holiday holidayPath={requestedHoliday} holidays={holidays} />
  ) : (
    <Navigate to='/404' />
  )
}
