import React from 'react'

const Countdown = ({ name, max, remaining }) => (
  <div className='row progress-row'>
    <div className='col'>
      <p className='counter-label'>{`${remaining} ${name}`}</p>
      <div className='progress'>
        <div
          className={'progress-bar progress-days'}
          role='progressbar'
          style={{ width: `${(remaining / max) * 100}%` }}
          aria-valuenow={(remaining / max) * 100}
          aria-valuemin='0'
          aria-valuemax='100'
          aria-label={`${name} remaining`}
        ></div>
      </div>
    </div>
  </div>
)

export default Countdown
