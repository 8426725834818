import React from 'react'

const Headline = ({ name }) => (
  <div className='container'>
    <div className='top-rule'></div>
    <div className='row'>
      <div className='main-headline'>
        <h1>
          How many days until...
          {name && (
            <>
              <br />
              {name}?
            </>
          )}
        </h1>
        {!name && (
          <p>Select a holiday below</p>
        )}
      </div>
    </div>
  </div>
)

export default Headline
