import React from 'react'
import { Link } from 'react-router-dom'

import './Dang.scss'

export const Dang = () => {
  return (
    <div className='contaniner dang'>
      <div className='content'>
        <h1>404</h1>
        <h2>Dang... that page doesn't exist</h2>
        <Link to='/'>www.xdaysuntil.com</Link>
      </div>
    </div>
  )
}
