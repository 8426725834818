import React from 'react'
import { Helmet } from 'react-helmet-async'

import Navbar from '../../navbar/Navbar.component'
import { Dang } from '../../Dang/Dang'

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name='description' content='This page does not exist'></meta>
        <meta property='robots' content='noindex' data-rh='true' />
      </Helmet>
      <Navbar />
      <Dang />
    </>
  )
}
