import DOMPurify from 'dompurify'

import { holidayConfigs } from '../../config/config'

const validateHoliday = (rawRequestedHoliday) => {
  const requestedHoliday = DOMPurify.sanitize(rawRequestedHoliday)
  const holiday = holidayConfigs.find((holiday) => {
    return holiday.path === requestedHoliday && holiday.enabled
  })
  return requestedHoliday === rawRequestedHoliday && holiday?.path
    ? true
    : false
}

export default validateHoliday
