import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { HolidayValidation } from '../HolidayValidation/HolidayValidation'
import { sortHolidays } from '../../utils/xdaysuntil-utils/xdaysuntil-utils'
import useGetHolidays from '../../hooks/useGetHolidays.hook'

import Home from '../views/Home/Home.component'
import { NotFound } from '../views/NotFound/NotFound.component'

function App() {
  const holidays = sortHolidays(useGetHolidays())

  return (
    <Routes>
      <Route path='/' element={<Home holidays={holidays} />}></Route>
      <Route
        path='countdown/:requestedHoliday'
        element={<HolidayValidation holidays={holidays} />}
      ></Route>
      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  )
}

export default App
