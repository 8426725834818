import ReactGA from 'react-ga4'

import { isProduction } from '../xdaysuntil-utils/xdaysuntil-utils'
import { GA_ID } from '../../constants/constants'

export const initialize = () => {
  isProduction() && ReactGA.initialize(GA_ID)
}

export default initialize
