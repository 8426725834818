export const redirects = [
  {
    pathname: '/thanksgiving',
    newPathname: '/countdown/thanksgiving',
  },
  {
    pathname: '/christmas',
    newPathname: '/countdown/christmas',
  },
  {
    pathname: '/easter',
    newPathname: '/countdown/easter',
  },
  {
    pathname: '/electionday',
    newPathname: '/countdown/election-day',
  },
  {
    pathname: '/election-day',
    newPathname: '/countdown/election-day',
  },
  {
    pathname: '/earthday',
    newPathname: '/countdown/earth-day',
  },
  {
    pathname: '/fathersday',
    newPathname: '/countdown/fathers-day',
  },
  {
    pathname: '/groundhogday',
    newPathname: '/countdown/groundhog-day',
  },
  {
    pathname: '/halloween',
    newPathname: '/countdown/halloween',
  },
  {
    pathname: '/laborday',
    newPathname: '/countdown/labor-day',
  },
  {
    pathname: '/mothersday',
    newPathname: '/countdown/mothers-day',
  },
  {
    pathname: '/stpatricksday',
    newPathname: '/countdown/st-patricks-day',
  },
  {
    pathname: '/valentines',
    newPathname: '/countdown/valentines-day',
  },
]

export default redirects
