import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import Navbar from '../../navbar/Navbar.component'
import Headline from '../../headline/Headline.component'
import Countdown from '../../Countdown/Countdown.component'
import When from '../../when/When.component'

import { shareCurrentPage } from '../../../utils/xdaysuntil-utils/xdaysuntil-utils'
import { BASE_URL } from '../../../constants/constants'

export const Holiday = ({ holidayPath, holidays }) => {
  const [holiday, setHoliday] = useState()

  useEffect(() => {
    setHoliday(holidays.find((holiday) => holiday.path === holidayPath))
  }, [holidayPath, holidays])

  const handleShareClick = (e) => {
    e.preventDefault()
    shareCurrentPage(holiday?.name)
  }

  return (
    <>
      <Helmet>
        <title>
          {holiday?.titleTag ||
            `How Many Days Until ${holiday?.name}? - xdaysuntil holiday countdown timer`}
        </title>
        <meta
          name='description'
          content={
            holiday?.descriptionTag ||
            `${holiday?.name} countdown timer to track the number of days, hours, minutes, and seconds remaining`
          }
        />
        <meta
          property='og:title'
          content={
            holiday?.titleTag ||
            `How Many Days Until ${holiday?.name}? - xdaysuntil holiday countdown timer`
          }
        ></meta>
        <meta
          property='og:description'
          content={
            holiday?.descriptionTag ||
            `${holiday?.name} countdown timer to track the number of days, hours, minutes, and seconds remaining`
          }
        ></meta>
        <link rel='canonical' href={`${BASE_URL}/countdown/${holiday?.path}`} />
      </Helmet>
      <Navbar />
      <Headline name={holiday?.name} />
      {holiday ? (
        <>
          <div className='container countdown-container'>
            <div key={holiday.name}>
              <Countdown
                name={'Days'}
                max={365}
                remaining={holiday.daysUntil}
              />
              <Countdown
                name={'Hours'}
                max={24}
                remaining={holiday.hoursUntil}
              />
              <Countdown
                name={'Minutes'}
                max={60}
                remaining={holiday.minutesUntil}
              />
              <Countdown
                name={'Seconds'}
                max={60}
                remaining={holiday.secondsUntil}
              />
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='more-countdowns-container d-grid gap-2 col-lg-8 col-sm-12 mx-auto'>
                {navigator.canShare && (
                  <div
                    className='btn btn-lg btn-block btn-share'
                    onClick={(e) => handleShareClick(e)}
                    data-testid='share-countdown-button'
                  >
                    Share This Countdown
                  </div>
                )}
                <Link to='/'>
                  <div className='btn btn-lg btn-block btn-more-countdowns'>
                    More Holidays
                  </div>
                </Link>
              </div>
            </div>
            <When name={holiday?.name} sugarDate={holiday.sugarDate} />
            <div className='holiday-summary'>{holiday.summary}</div>
          </div>
        </>
      ) : (
        <>Loading...</>
      )}
    </>
  )
}

export default Holiday
