import { useEffect, useState } from 'react'

const useCurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const updateCurrentDate = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => clearInterval(updateCurrentDate)
  }, [])

  return currentDate
}

export default useCurrentDate
