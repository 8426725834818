import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Navbar from '../../navbar/Navbar.component'
import Headline from '../../headline/Headline.component'
import { HolidayList } from '../../HolidayList/HolidayList.component'

import { BASE_URL } from '../../../constants/constants'

export const Home = ({ holidays }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <Helmet>
        <title>
          Count Down the Days with xdaysuntil - The Premier Holiday Countdown
          Timer App
        </title>
        <meta
          name='description'
          content="Need a countdown timer and reminder app? Look no further than xdaysuntil! With our easy-to-use interface and precise countdown calculations, you'll never miss an important event again."
        ></meta>
        <meta
          property='og:title'
          content='Count Down the Days with xdaysuntil - The Premier Holiday Countdown Timer App'
        ></meta>
        <meta
          property='og:description'
          content="Need a countdown timer and reminder app? Look no further than xdaysuntil! With our easy-to-use interface and precise countdown calculations, you'll never miss an important event again."
        ></meta>
        <link rel='canonical' href={BASE_URL} />
      </Helmet>
      <Navbar />
      <Headline />
      <HolidayList holidays={holidays} />
    </>
  )
}

export default Home
