import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { handleRedirect } from './utils/xdaysuntil-utils/xdaysuntil-utils'
import initialize from './utils/initialize/initialize'
import App from './components/App/App'

import { MESSAGES } from './constants/constants'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import './index.scss'

const redirect = handleRedirect()

if (!redirect) {
  console.info(MESSAGES.STARTUP)

  initialize()

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root') || document.createElement('div')
  )

  console.info(MESSAGES.LOADED)
}
