import ReactGA from 'react-ga4'

import { redirects } from '../../config/redirects'
import { DOMAIN, MESSAGES } from '../../constants/constants'

export const isProduction = () => {
  return window.location.hostname.split('.').slice(-2).join('.') === DOMAIN
}

export const handleRedirect = () => {
  const requestPathname = window.location.pathname.toLowerCase()
  const requestPathnameLastChar = requestPathname.slice(-1)
  const redirect = redirects.find(
    (redirect) =>
      redirect.pathname === requestPathname ||
      (redirect.pathname === requestPathname.slice(0, -1) &&
        requestPathnameLastChar === '/')
  )?.newPathname

  redirect && window.location.replace(redirect)
  return !!redirect
}

export const sortHolidays = (holidays) => {
  return holidays.sort((a, b) => {
    return a.sortName < b.sortName ? -1 : a.sortName < b.sortName ? 1 : 0
  })
}

export const shareCurrentPage = async (id) => {
  const action = `SHARE: ${id.toUpperCase()}`
  const shareData = {
    url: document.querySelector("link[rel='canonical']").getAttribute('href'),
  }
  navigator
    .share(shareData)
    .then(() => {
      ReactGA.event({
        category: 'share',
        action,
      })
    })
    .catch((e) => {
      console.info(MESSAGES.SHARING_FAILED_CANCELLED)
    })
}
