export const BASE_URL = 'https://www.xdaysuntil.com'
export const DOMAIN = 'xdaysuntil.com'
export const GA_ID = 'G-C99NB95N7L'

export const MESSAGES = {
  STARTUP: `Loading ${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`,
  LOADED: 'Loading complete',
  SHARING_INITIATED: 'Sharing initiated',
  SHARING_FAILED_CANCELLED: 'Sharing cancelled',
}
