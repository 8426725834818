import React from 'react'
import { Link } from 'react-router-dom'

export const HolidayList = ({ holidays }) => (
  <div className='container'>
    {holidays.map((holiday) => {
      return (
        <Link key={holiday.name} to={`/countdown/${holiday.path}`}>
          <div className='row'>
            <div className='holiday-selection'>
              <h2>{holiday.name}</h2>
            </div>
          </div>
        </Link>
      )
    })}
  </div>
)
