import React from 'react'

const When = ({ name, sugarDate }) => (
  <div className='row'>
    <div className='date'>
      <h2>When is {name}?</h2>
      <p>{sugarDate.format('{Weekday}, {Month} {date}, {year}').raw}</p>
    </div>
  </div>
)

export default When
