import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => (
  <nav className='navbar sticky-top'>
    <div className='container-fluid'>
      <Link to='/'>
        <div className='navbar-brand xdaysuntil-brand'>xdaysuntil</div>
      </Link>
    </div>
  </nav>
)

export default Navbar
